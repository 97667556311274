import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import type { MetaFunction, LinksFunction } from '@remix-run/cloudflare'
import { Links, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useRouteError } from '@remix-run/react'
import type { PropsWithChildren } from 'react'

import styles from '~/global.css?url'
import Error from './error'

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }]

export const meta: MetaFunction = () => [
	{ charset: 'utf-8' },
	{ title: 'Chronicle.chat' },
	{ name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
]

export function Layout({ children }: PropsWithChildren) {
	return (
		<html lang="en">
			<head>
				<Meta />
				<Links />
			</head>
			<body className="bg-gray-50 antialiased">
				{children}

				{/* Manages scroll position for client-side transitions */}
				{/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
				<ScrollRestoration />

				{/* Script tags go here */}
				{/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
				<Scripts />
			</body>
		</html>
	)
}

function App() {
	return <Outlet />
}

export default withSentry(App)

export function ErrorBoundary() {
	const error = useRouteError()

	captureRemixErrorBoundaryError(error)
	console.error('[errorBoundary]', error)

	if (isRouteErrorResponse(error)) {
		if (error.status === 404) {
			return <Error heading="Page not found" message="We are sorry, but this page was not found." />
		} else {
			return <Error heading="Unexpected error" message="We are sorry but an unexpected error has occurred." />
		}
		// ? `${error.status} ${error.statusText}`
		// : error instanceof Error ? error.message : "Unknown Error"
	}
}

// export function ErrorBoundary() {
//     const error = useRouteError();
//     console.error(error);
//     return (
//         <html>
//             <head>
//                 <title>Oh no!</title>
//                 <Meta />
//                 <Links />
//             </head>
//             <body>
//                 {/* add the UI you want your users to see */}
//                 <Scripts />
//             </body>
//         </html>
//     );
// }
