import type { PropsWithChildren } from 'react'

export default function Error({ heading, message }: PropsWithChildren<{ heading?: string; message?: string }>) {
	return (
		<main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
			<div className="text-center">
				<p className="text-base font-semibold text-orange-500">404</p>
				<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{heading}</h1>
				<p className="mt-6 text-base leading-7 text-gray-600">{message}</p>
				<div className="mt-10 flex items-center justify-center gap-x-6">
					<a
						href="/"
						className="rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
					>
						Home page
					</a>
					<a href="mailto:support@chronicle.chat" className="text-sm font-semibold text-gray-900">
						Contact support<span aria-hidden="true">&rarr;</span>
					</a>
				</div>
			</div>
		</main>
	)
}
